/*#navigation {*/
/*    padding-left: 10px;*/
/*    padding-right: 10px;*/
/*    border-right: #9ca3af solid 1px;*/
/*}*/

#content, #qcm {
    padding-left: 10px;
    padding-right: 10px;
}

/*#navigation > ol > li > :is(h1, h2, h3, h4, h5, h6), #content > :is(h1, h2, h3, h4, h5, h6), #content > div > :is(h1, h2, h3, h4, h5, h6) {*/
/*    margin-top: 10px;*/
/*    color: cornflowerblue;*/
/*    font-weight: bold;*/
/*}*/

#content > :is(h1, h2, h3, h4, h5, h6), #content > div > :is(h1, h2, h3, h4, h5, h6) {
    margin-top: 10px;
    color: cornflowerblue;
    font-weight: bold;
}

#content > div > :is(h1, h2, h3, h4, h5, h6) {
    margin-top: 30px;
}

#qcm > div > :is(h1, h2, h3, h4, h5, h6) {
    color: cornflowerblue;
    font-weight: bold;
}

#content h1 {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    text-decoration: underline;
}

#content h2 {
    padding-left: 0;
}

#content h3 {
    padding-left: 10px;
}

#content h4 {
    padding-left: 20px;
}

#content h5 {
    padding-left: 30px;
}

#content h6 {
    padding-left: 40px;
}

#content img {
    margin: auto;
}

#content p {
    margin: 16px 0 16px 0;
}

#content ul > li {
    margin: 5px 0 5px 20px;
    list-style-type: disc;
}

#content > div > ul {
    list-style-type: none;
    padding: 0;
}

#content > div > ul > li {
    display: flex;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 8px;
}

#content > div > ul > li > p {
    margin-right: 10px;
    display: contents;
}

#content > div > ul > li > input[type="checkbox"], #content > div > ul > li > p > input[type="checkbox"] {
    margin-right: 10px;
    width: 20px;
    height: 20px;
}

#content ol > li {
    margin: 5px 0 5px 20px;
    list-style-type: decimal;
}

.dropdown_menu:has(~ .dropdown_item > div > menu > li > a.active) {
    font-weight: bold;
    text-decoration: underline;
}

#content .hljs {
    border-radius: 4px;
    border-color: #9ca3af;
    border-width: 2px;
    white-space: pre-wrap;
}

#content p a {
    margin: 0 0.2em;
    padding: 3px;
    background: #97CAF2;
    border-radius: 2px;
    transition: all 0.3s ease-out;
    text-decoration: none;
    font-weight: bold;
    color: white;
}

#content p a:hover {
    background: #4aa5ea
}

#content p a:active {
    background: #97c1fc
}


#content table {
    border: rgb(80, 93, 111) solid 1px;
    width: 100%;
    table-layout: fixed;
}

#content th {
    border: rgb(80, 93, 111) solid 1px;
    background-color: rgb(51, 65, 85);
    color: rgb(226, 232, 240);
    text-align: center;
    padding: 10px;
}

#content td {
    border: rgb(80, 93, 111) solid 1px;
    text-align: left;
    padding: 10px;
}

#content td:first-child {
    /*white-space: nowrap;*/
}

#content td:nth-of-type(3) {
    /*white-space: nowrap;*/
}

#content td, #content code, #content pre code {
    text-wrap: wrap;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.html_render {
    width: 100%;
}

@media print {
    /** {*/
    /*    page-break-inside: avoid !important;*/
    /*    page-break-after: avoid !important;*/
    /*    page-break-before: avoid !important;*/
    /*}*/

    html {
        /*margin: 1px;*/
        /*font-family: serif; !* system-ui *!*/
    }

    h1, h2, h3, h4, h5, h6 {
        padding-left: 0;
    }

    /*body > * {*/
    /*margin: 20px;*/
    /*width: 66.667%;*/
    /*zoom: .666;*/
    /*}*/

    * {
        /*background-color: rgb(241 245 249 / var(--tw-bg-opacity))*/
        background-color: white;
    }

    html, body {
        height: 100%;
        width: 100%;
        overflow: visible;
        margin: 0;
        padding: 0;
    }

    .bg-slate-100 {
        background-color: white;
    }

    header {
        display: none;
    }

    #navigation {
        display: none;
        margin: 0;
    }

    nav {
        display: none;
        margin: 0;
    }

    #content, #main {
        width: 100%;
        overflow: visible;
        margin: 0;
    }

    footer {
        display: none;
    }
}