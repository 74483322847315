.reveal-viewport nav {
    display: none;
}

.reveal-viewport img {
    margin: auto;
    width: 400px;
}

.reveal-viewport img.img_background {
    background-color: white;
    border-radius: 50%;
}

.reveal-viewport table:not(.hljs-ln) td:not(.hljs-ln-line):not(:first-child), .reveal-viewport table th:not(:first-child) {
    border-left: 1px solid;
}

.reveal-viewport table th {
    text-align: center;
}

.reveal-viewport pre {
    width: 100%;
}

.reveal-viewport .cols_display {
    display: flex;
}

.reveal-viewport .cols_display_col {
    flex: 1;
}

.reveal-viewport ul {
    list-style-position: outside;
    list-style-type: "\27A4";
}

.reveal-viewport ul li {
    padding-left: 0.3em;
}

.disable {
    filter: grayscale(75%);
}